import { ethers } from "ethers";
import React from "react";
import { Link } from "react-router-dom";

import { config } from "../../config";
import store from "../../store";
import { loadIdentity } from "../../modules/identity";

class MethodsManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      identity: null,
      value: null,
      claims: [],
    };
  }

  async componentDidMount() {
    this.provider =
      store.getState().provider ||
      new ethers.providers.Web3Provider(window.ethereum);
    this.signer = store.getState().signer || this.provider.getSigner();
    this.storedAddress = window.sessionStorage.getItem(
      config["session_storage_id_address"]
    );

    try {
      this.identity = await loadIdentity(
        this.storedAddress,
        this.provider,
        this.signer
      );
      this.setState({ identity: this.identity });
    } catch (error) {
      console.error(error);
      return;
    }
    this.getClaims();
  }

  async getClaims() {
    this.setState({ value: null, claims: [] });
    for (const [, topic] of Object.entries(config.topics)) {
      const claimsId = await this.identity.getClaimIdsByTopic(topic);
      if (claimsId.length > 0) {
        claimsId.forEach(async (claimId) => {
          const claim = await this.identity.getClaim(claimId);
          this.setState({ value: claim });
          this.setState((state) => {
            const claims = [...state.claims, state.value];

            return {
              claims,
              value: null,
            };
          });
        });
      }
    }
  }

  replaceClaimInformationText(claimId, htmlTag) {
    document
      .getElementById("claim-" + claimId)
      .getElementsByClassName("div-claim-infos")[0].innerHTML = htmlTag;
  }

  async removeClaim(claimId) {
    console.log(claimId);
    document
      .getElementById("claim-" + claimId)
      .getElementsByClassName("button-claim-revoke")[0]
      .remove();

    const htmlTagProcessing = `
      <div class="col-span-6 lg:col-span-6 mx-auto">
        <div class="flex loader ease-linear mx-auto rounded-full border-4 border-t-4 border-gray-200 h-7 w-7 sm:h-12 sm:w-12"></div>
      </div>
      <p class="col-span-6 lg:col-span-6 text-center m-4">The claim is being removed... This message will disappear when the operation is done.</p>`;
    this.replaceClaimInformationText(claimId, htmlTagProcessing);

    this.setState({ sendingTransactionToBlockchain: true });
    try {
      const tx = await this.identity
        .removeClaim(claimId, {
          signer: this.signer,
        })
        .catch((e) => {
          console.error("The transaction has been rejected on Metamask!");
          const htmlTagRejected = `<p class="col-span-6 lg:col-span-6 text-center m-4">The transaction has been rejected on Metamask!</p>`;
          this.replaceClaimInformationText(claimId, htmlTagRejected);
        });
      await tx.wait();
      document.getElementById("claim-" + claimId).innerHTML = "";
    } catch (error) {
      console.error("Error while processing the transaction", error);
      this.setState({ errorRemovingClaim: true });
    }
    this.setState({ sendingTransactionToBlockchain: false });
  }

  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  render() {
    return (
      <div className="claim_management flex flex-col mx-auto text-center rounded-lg text-gray-900 m-2 w-full lg:w-2/3">
        {this.state.claims.length > 0 ? (
          <>
            <div className="flex flex-row mb-5">
              <h1 className="text-md text-2xl w-1/2 font-semibold text-gray-700 dark:text-gray-200 overflow-hidden overflow-ellipsis text-left">
                Your authentication methods
              </h1>
              <div className="flex justify-end w-1/2">
                <Link
                  to="/add"
                  className="bg-purple-500 focus:bg-purple-600 text-white rounded-md focus:outline-none w-full sm:max-w-xs px-5 py-2 "
                >
                  + Add methods to your identity
                </Link>
              </div>
            </div>
            <div className="claims-list">
              {this.state.claims.map((claim) => (
                <div
                  className="relative"
                  key={claim.id}
                  id={"claim-" + claim.id}
                >
                  <div className="flex bg-intech-secondary rounded-t-xl select-none text-white">
                    <p className="px-5 py-2 m-1 font-bold mx-auto">
                      {this.capitalize(
                        this.getKeyByValue(
                          config["topics"],
                          claim.topic.toString()
                        )
                      )}{" "}
                      claim
                    </p>
                    <button
                      onClick={() => this.removeClaim(claim.id)}
                      className="button-claim-revoke inline-flex items-center justify-center w-10 h-10 rounded-full focus:shadow-outline absolute right-1 top-1 transform transition-transform hover:scale-110"
                    >
                      <i className="fas fa-times"></i>
                    </button>
                  </div>

                  <div className="div-claim-infos py-5 mb-12 shadow-md grid grid-cols-6 px-2 lg:px-7">
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Id:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.id}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Address:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.address}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      URI:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.uri}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Issuer:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.issuer}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Signature:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.signature}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Scheme:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.scheme}
                    </p>
                    <p className="col-span-6 lg:col-span-1 text-left lg:text-right ml-2 lg:ml-0 lg:mr-7 font-bold mt-2">
                      Data:
                    </p>
                    <p className="col-span-6 lg:col-span-5 text-left ml-2 lg:ml-0 overflow-hidden overflow-ellipsis lg:mt-2">
                      {claim.data}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <Link
              to="/add"
              className="mx-auto m-2 bg-purple-500 focus:bg-purple-600 text-white rounded-md focus:outline-none w-full sm:max-w-xs px-5 py-2 "
            >
              + Add methods to your identity
            </Link>
          </>
        )}
      </div>
    );
  }
}

export default MethodsManagement;
