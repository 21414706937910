import ClosingAlert from "../ClosingAlert";

export default function ClosingValidationAlert(props) {
  return (
    <>
      <ClosingAlert
        color="green"
        boldText="Congrats !"
        text={
          "The claim is on the blockchain ! You can use " +
          props.method +
          " as an authentication method now !"
        }
      ></ClosingAlert>
    </>
  );
}
