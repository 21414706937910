import React from "react";

class SignatureHelper extends React.Component {
  render() {
    return (
      <div className="SignupHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Signature Screen</h1>
          <p className="mx-auto text-justify w-5/6">
            No particular help on Signature Page.
          </p>
        </section>
      </div>
    );
  }
}

export default SignatureHelper;
