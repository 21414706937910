import React from "react";

import store from "../store";
import LoginHelper from "./LoginHelper";
import SignupHelper from "./SignupHelper";
import SignatureHelper from "./SignatureHelper";
import ManageHelper from "./ManageHelper";
import AddingHelper from "./AddingHelper";

class Helper extends React.Component {
  constructor() {
    super();
    this.state = {
      display: false,
      location: store.getState().location,
    };
    this.display = this.display.bind(this);
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({ location: store.getState().location });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  display() {
    this.setState({ display: !this.state.display });
  }

  render() {
    let helperCSS = " hidden";
    this.props.guideMode ? (helperCSS += " hidden") : (helperCSS = "");
    let content = <div>Error with the manual view. Please retry later.</div>;
    switch (this.state.location) {
      case "/add":
        content = <AddingHelper />;
        break;
      case "/login":
        content = <LoginHelper />;
        break;
      case "/manage":
        content = <ManageHelper />;
        break;
      case "/signature":
        content = <SignatureHelper />;
        break;
      case "/signup":
        content = <SignupHelper />;
        break;
      default:
        console.error("This location does not exist");
    }

    return <div className="Helper">{content}</div>;
  }
}

export default Helper;
