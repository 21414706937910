import React from "react";
import { ethers } from "ethers";

import { config } from "../config";
import store from "../store";
import GoogleButton from "../Buttons/Google/GoogleButton";
import GitHubButton from "../Buttons/GitHub/GitHubLogin";
import MicrosoftButton from "../Buttons/Microsoft/MicrosoftButton";
import WebAuthnButton from "../Buttons/WebAuthn/WebAuthnButton";
import { validateJWTGoogle, validateJWTMicrosoft } from "../modules/jwt";
import { sendClaimToBlockchain, prepareClaim } from "../modules/claim";
import { loadIdentity } from "../modules/identity";
import { hashStrInSha256 } from "../modules/utils";
import ClosingAlert from "../Alerts/ClosingAlert.js";
import ClosingInformationAlert from "../Alerts/MethodsAdding/ClosingInformationAlert.js";
import ClosingValidationAlert from "../Alerts/MethodsAdding/ClosingValidationAlert.js";
import ExportGistGitHub from "../Modal/ExportGistGitHub";

class MethodsAdding extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      displayModal: false,
      errorGoogleClaim: false,
      errorMicrosoftClaim: false,
      errorFIDOClaim: false,
      sendingGoogleClaimToBlockchain: false,
      sendingMicrosoftClaimToBlockchain: false,
      sendingFIDOClaimToBlockchain: false,
      isGoogleClaimSent: false,
      isMicrosoftClaimSent: false,
      isFIDOClaimSent: false,
    };
  }

  async componentDidMount() {
    this.provider =
      store.getState().provider ||
      new ethers.providers.Web3Provider(window.ethereum);
    this.signer = store.getState().signer || this.provider.getSigner();
    this.storedAddress = window.sessionStorage.getItem(
      config["session_storage_id_address"]
    );
    this.identity = await loadIdentity(
      this.storedAddress,
      this.provider,
      this.signer
    );

    this.unsubscribe = store.subscribe(() => {
      if (
        store.getState().guideMode &&
        store.getState().helperState === "Google" &&
        store.getState().animationStep === 2
      ) {
        this.sendGoogleIdToBlockchain(this.googleId).then(() => {
          this.googleId = "";
        });
      }

      if (
        store.getState().guideMode &&
        store.getState().helperState === "Microsoft" &&
        store.getState().animationStep === 2
      ) {
        this.sendMicrosoftIdToBlockchain(this.microsoftId).then(() => {
          this.microsoftId = "";
        });
      }

      if (
        store.getState().guideMode &&
        store.getState().helperState === "GitHub" &&
        store.getState().animationStep === 3
      ) {
        this.sendGitHubIdToBlockchain(this.githubId).then(() => {
          this.githubId = "";
        });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  async handleGitHubFlow(response) {
    if (response) {
      this.githubId = response;
      if (!store.getState().guideMode) {
        await this.sendGitHubIdToBlockchain(response);
      }
    } else this.setState({ errorGitHubClaim: true });
  }

  async sendGitHubIdToBlockchain(idGitHub) {
    const hash = hashStrInSha256(idGitHub);
    let claim = null;
    try {
      claim = await prepareClaim(
        this.storedAddress,
        hash,
        config.topics.github,
        this.signer
      );
    } catch (error) {
      store.dispatch({ type: "setAnimationStep", animationStep: 10 });
      this.setState({ errorGitHubClaim: true });
    }

    this.setState({
      errorGitHubClaim: false,
      sendingGitHubClaimToBlockchain: true,
      isGitHubClaimSent: false,
    });

    try {
      const tx = await sendClaimToBlockchain(this.identity, claim, this.signer);
      await tx.wait();
      store.dispatch({ type: "setAnimationStep", animationStep: 9 });
    } catch (error) {
      console.error("Error while processing the transaction", error);
      this.setState({ errorGitHubClaim: true });
    }
    this.setState({ sendingGitHubClaimToBlockchain: false });
    if (!this.state.errorGitHubClaim) {
      this.setState({ isGitHubClaimSent: true });
    }
  }

  incrementAnimationStep() {
    if (store.getState().guideMode) {
      store.dispatch({
        type: "setAnimationStep",
        animationStep: store.getState().animationStep + 1,
      });
    }
  }

  async sendGoogleIdToBlockchain(googleId) {
    const hashGoogleId = hashStrInSha256(googleId);
    let claim = null;
    try {
      claim = await prepareClaim(
        this.storedAddress,
        hashGoogleId,
        config.topics.google,
        this.signer
      );
    } catch (error) {
      store.dispatch({ type: "setAnimationStep", animationStep: 10 });
      this.setState({ errorGoogleClaim: true });
    }

    this.setState({
      errorGoogleClaim: false,
      sendingGoogleClaimToBlockchain: true,
      isGoogleClaimSent: false,
    });

    try {
      const tx = await sendClaimToBlockchain(this.identity, claim, this.signer);
      await tx.wait();
    } catch (error) {
      console.error("Error while processing the transaction", error);
      this.setState({ errorGoogleClaim: true });
      store.getState().guideMode &&
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 10,
        });
    }
    this.setState({ sendingGoogleClaimToBlockchain: false });
    if (!this.state.errorGoogleClaim) {
      this.setState({ isGoogleClaimSent: true });
      store.getState().guideMode &&
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 9,
        });
    }
  }

  async handleGoogleLogIn(response) {
    const ID_TOKEN = response.getAuthResponse().id_token;

    if (await validateJWTGoogle(ID_TOKEN, config["googleClientId"])) {
      this.googleId = response.Aa;
      if (!store.getState().guideMode) {
        this.sendGoogleIdToBlockchain(this.googleId);
      }
      this.incrementAnimationStep();
    }
  }

  handleGoogleLogOut() {}

  handleMicrosoftLogIn(response) {
    validateJWTMicrosoft(response.idToken).then(async (isValid) => {
      if (isValid) {
        this.microsoftId = response.idTokenClaims.oid;
        if (!store.getState().guideMode) {
          this.sendMicrosoftIdToBlockchain(this.microsoftId);
        }
        this.incrementAnimationStep();
      }
    });
  }

  async sendMicrosoftIdToBlockchain(idMicrosoft) {
    const hash = hashStrInSha256(idMicrosoft);
    let claim = null;
    try {
      claim = await prepareClaim(
        this.storedAddress,
        hash,
        config.topics.microsoft,
        this.signer
      );
    } catch (error) {
      store.dispatch({ type: "setAnimationStep", animationStep: 10 });
      this.setState({ errorMicrosoftClaim: true });
    }

    this.setState({
      errorMicrosoftClaim: false,
      sendingMicrosoftClaimToBlockchain: true,
      isMicrosoftClaimSent: false,
    });

    try {
      const tx = await sendClaimToBlockchain(this.identity, claim, this.signer);
      await tx.wait();
    } catch (error) {
      console.error("Error while processing the transaction", error);
      this.setState({ errorMicrosoftClaim: true });
      store.getState().guideMode &&
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 10,
        });
    }
    this.setState({ sendingMicrosoftClaimToBlockchain: false });
    if (!this.state.errorMicrosoftClaim) {
      this.setState({ isMicrosoftClaimSent: true });
      store.getState().guideMode &&
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 9,
        });
    }
  }

  handleMicrosoftLogOut(response) {}

  async handleFIDOLogIn(response) {
    this.userFIDO = response;
    this.setState({ FIDOLoggedIn: true });

    const fidoID = response.id;
    if (store.getState().guideMode) {
      store.dispatch({
        type: "setAnimationStep",
        animationStep: store.getState().animationStep + 1,
      });
    }
    if (fidoID != null) {
      const hashId = hashStrInSha256(fidoID);
      const data = {
        credentialId: response.authData.credentialId,
        publicKeyBytes: response.authData.publicKeyBytes,
        hashId: hashId,
      };
      const data2hex = "0x" + Buffer.from(JSON.stringify(data)).toString("hex");
      let claim = null;
      try {
        claim = await prepareClaim(
          this.storedAddress,
          data2hex,
          config.topics.fido,
          this.signer
        );
      } catch (error) {
        console.error("Error while processing the transaction", error);
        this.setState({ errorFIDOClaim: true });
        store.dispatch({
          type: "setAnimationStep",
          animationStep: 10,
        });
      }

      this.setState({
        errorFIDOClaim: false,
        sendingFIDOClaimToBlockchain: true,
        isFIDOClaimSent: false,
      });
      try {
        const tx = await sendClaimToBlockchain(
          this.identity,
          claim,
          this.signer
        );
        await tx.wait();
      } catch (error) {
        console.error("Error while processing the transaction", error);
        this.setState({ errorFIDOClaim: true });
        store.getState().guideMode &&
          store.dispatch({
            type: "setAnimationStep",
            animationStep: 10,
          });
      }
      this.setState({ sendingFIDOClaimToBlockchain: false });
      if (!this.state.errorFIDOClaim) {
        this.setState({ isFIDOClaimSent: true });
        store.getState().guideMode &&
          store.dispatch({
            type: "setAnimationStep",
            animationStep: 9,
          });
      }
    }
  }

  async openGitHubModal(response) {
    if (this.storedAddress && response.login && response.id) {
      var json = {};
      json.identity_address = this.storedAddress;
      json.username = response.login;
      json.id = response.id;
      const signature = await this.signer.signMessage(JSON.stringify(json));
      json.signature = signature;
      this.githubData = json;
      this.setState({ displayModal: true });
    }
  }

  closeGitHubModal() {
    this.setState({
      displayModal: false,
    });
  }

  comeBack() {
    this.props.history.goBack();
  }

  render() {
    return (
      <>
        <button
          onClick={() => this.comeBack()}
          className="mx-right m-2 bg-green-400 focus:bg-green-500 text-white rounded-md focus:outline-none w-full sm:max-w-xs px-5 py-2"
        >
          {"<"} Come back to identity management
        </button>
        <div className="my-10">
          {this.state.displayModal && (
            <ExportGistGitHub
              data={this.githubData}
              close={() => this.closeGitHubModal()}
              onSuccess={(response) => this.handleGitHubFlow(response)}
            ></ExportGistGitHub>
          )}

          <div className="mx-5 xl:mx-40">
            {this.state.isGoogleClaimSent && (
              <ClosingValidationAlert method="Google"></ClosingValidationAlert>
            )}
            {this.state.isMicrosoftClaimSent && (
              <ClosingValidationAlert method="Microsoft"></ClosingValidationAlert>
            )}
            {this.state.isFIDOClaimSent && (
              <ClosingValidationAlert method="FIDO"></ClosingValidationAlert>
            )}
            {this.state.isGitHubClaimSent && (
              <ClosingValidationAlert method="GitHub"></ClosingValidationAlert>
            )}
            {this.state.sendingGoogleClaimToBlockchain && (
              <ClosingInformationAlert method="Google"></ClosingInformationAlert>
            )}
            {this.state.sendingMicrosoftClaimToBlockchain && (
              <ClosingInformationAlert method="Microsoft"></ClosingInformationAlert>
            )}
            {this.state.sendingFIDOClaimToBlockchain && (
              <ClosingInformationAlert method="FIDO"></ClosingInformationAlert>
            )}
            {this.state.sendingGitHubClaimToBlockchain && (
              <ClosingInformationAlert method="GitHub"></ClosingInformationAlert>
            )}
            {(this.state.errorGoogleClaim ||
              this.state.errorMicrosoftClaim ||
              this.state.errorFIDOClaim ||
              this.state.errorGitHubClaim) && (
              <ClosingAlert
                color="red"
                boldText="Whoops !"
                text="There have been a problem during the claim sending. Please retry later."
              ></ClosingAlert>
            )}
          </div>
          <div className="grid grid-cols-1 xl:grid-cols-2 mx-7">
            <div className="mx-4 mb-20">
              <div className="bg-intech-primary mb-1 rounded-t-xl z-0 select-none text-white">
                <p className="py-3 font-bold text-center">
                  Google Authentification
                </p>
              </div>
              <div className="bg-gray-50 py-2 shadow-md px-2 lg:px-7 h-full">
                <div>
                  <GoogleButton
                    onLoggedIn={(response) => this.handleGoogleLogIn(response)}
                  />
                </div>
              </div>
            </div>

            <div className="mx-4 mb-20">
              <div className="bg-intech-primary mb-1 rounded-t-xl z-0 select-none text-white ">
                <p className="py-3 font-bold text-center">
                  Microsoft Authentification
                </p>
              </div>
              <div className="bg-gray-50 py-2 shadow-md px-2 lg:px-7 h-full">
                <div>
                  <MicrosoftButton
                    onLoggedIn={(response) =>
                      this.handleMicrosoftLogIn(response)
                    }
                    onLoggedOut={(response) =>
                      this.handleMicrosoftLogOut(response)
                    }
                  />
                </div>
              </div>
            </div>
            <div className="mx-4 mb-20">
              <div className="bg-intech-primary mb-1 rounded-t-xl z-0 select-none text-white">
                <p className="py-3 font-bold text-center">
                  FIDO Authentification
                </p>
              </div>
              <div className="bg-gray-50 py-2 shadow-md px-2 lg:px-7 h-full">
                <div>
                  <WebAuthnButton
                    identity={this.identity}
                    onLoggedIn={(response) => this.handleFIDOLogIn(response)}
                  />
                </div>
              </div>
            </div>
            <div className="mx-4 mb-20">
              <div className="bg-intech-primary mb-1 rounded-t-xl z-0 select-none text-white">
                <p className="py-3 font-bold text-center">
                  GitHub Authentification
                </p>
              </div>
              <div className="bg-gray-50 py-2 shadow-md px-2 lg:px-7 h-full">
                <div>
                  <GitHubButton
                    onSuccess={(response) => this.openGitHubModal(response)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MethodsAdding;
