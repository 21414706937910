import { createBrowserHistory } from "history";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";

import "./index.css";

import App from "./App/App.js";
import reportWebVitals from "./reportWebVitals";
import store from "./store";

const history = createBrowserHistory();

store.dispatch({ type: "setLocation", location: history.location.pathname });
history.listen((location) => {
  store.dispatch({ type: "setLocation", location: location.pathname });
});

ReactDOM.render(
  <React.StrictMode>
    <Router history={history}>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
