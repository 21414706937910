import React from "react";

import "./Spinner.css";

class Spinner extends React.Component {
  render() {
    return (
      <div
        className="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="pr-4">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <div className="flex w-full max-h-full justify-center">
                    <div className="flex loader ease-linear rounded-full border-8 border-t-8 border-gray-200 h-14 w-14 sm:h-24 sm:w-24"></div>
                  </div>
                  <div className="text-center text-greatBlue mt-7 mx-4 text-2xl">
                    {this.props.title}
                  </div>
                  <div className="text-center text-greatBlue mt-2 mx-4 text-l">
                    {this.props.text}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Spinner;
