import React from "react";

import ENSManagementComponent from "./Components/ENSManagementComponent";
import ClaimManagementComponent from "./Components/ClaimManagementComponent";

import "./Spinner.css";

class MethodsManagement extends React.Component {
  render() {
    return (
      <div className="flex flex-col mx-auto text-center rounded-lg text-gray-900 m-2">
        <ENSManagementComponent></ENSManagementComponent>

        <ClaimManagementComponent></ClaimManagementComponent>
      </div>
    );
  }
}

export default MethodsManagement;
