import React from "react";

import store from "../store";

class ExportGistGitHub extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
    };
  }

  async verifyNewGist() {
    const gists = await fetch(
      `https://api.github.com/users/${this.props.data.username}/gists`,
      {
        method: "GET",
      }
    ).then(async (response) => await response.json());
    var gistFound = false;
    var comp = 0;
    while (!gistFound && comp < gists.length) {
      const element = gists[comp];
      const gistDetails = await fetch(
        `https://api.github.com/gists/${element.id}`,
        {
          method: "GET",
        }
      ).then(async (response) => await response.json());
      if (gistDetails.files.hasOwnProperty("ONCHAINID.md")) {
        if (
          gistDetails["files"]["ONCHAINID.md"]["content"] ===
          JSON.stringify(this.props.data)
        ) {
          gistFound = true;
          this.props.close();
        }
      }
      comp++;
    }

    if (gistFound) {
      store.dispatch({ type: "setAnimationStep", animationStep: 2 });
      this.props.onSuccess(this.props.data.id);
    } else {
      store.dispatch({ type: "setAnimationStep", animationStep: 20 });
      this.setState({ error: "Gist not found" });
    }
  }

  render() {
    return (
      <div className="">
        <div
          className="fixed z-10 inset-0 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="pr-4">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg leading-6 font-medium text-gray-900"
                      id="modal-title"
                    >
                      Prove your GitHub identity
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Please login to GitHub and save a public gist called
                        ONCHAINID.md.
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-xs text-gray-500">
                        There can be some minutes before the GitHub Gist is
                        available.
                      </p>
                    </div>
                    <div className="mt-2">
                      <textarea
                        className="w-full bg-gray-300 text-gray-600 shadow-sm border border-gray-300 rounded-md p-1"
                        value={JSON.stringify(this.props.data)}
                        rows="7"
                        readOnly
                      ></textarea>
                    </div>
                    <div className="mt-2">
                      <p className="text-xs text-gray-500">
                        Connect to your GitHub account and check that the gist
                        you created is public. The gist should be add in{" "}
                        <a
                          className="text-blue-700 underline"
                          href={
                            "https://gist.github.com/" +
                            this.props.data.username
                          }
                        >
                          here
                        </a>
                        .
                      </p>
                    </div>
                    <div className="mt-2">
                      <p className="text-sm text-red-500">{this.state.error}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  onClick={() => this.verifyNewGist()}
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-intech-primary text-base font-medium text-white hover:bg-intech-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-intech-primary sm:ml-3 sm:w-auto sm:text-sm"
                >
                  {!this.state.error
                    ? "Check on GitHub profile"
                    : "Retry later"}
                </button>
                <button
                  type="button"
                  onClick={() => this.props.close()}
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ExportGistGitHub;
