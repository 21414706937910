import { config } from "../config";
import store from "../store";

function login(address) {
  window.sessionStorage.setItem(config["session_storage_id_address"], address);
  store.dispatch({ type: 'setIsConnected', isConnected:true });
}

function logout() {
  window.sessionStorage.removeItem(config["session_storage_id_address"]);
  store.dispatch({ type: 'setIsConnected', isConnected:false });
}

function isLogin() {
  return store.getState().isConnected;
}

export { isLogin, login, logout };
