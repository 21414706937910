import { IdentitySDK } from "@onchain-id/identity-sdk";
import { ethers } from "ethers";
import React from "react";
import { Link, withRouter } from "react-router-dom";

import store from "../store";
import ClosingAlert from "../Alerts/ClosingAlert.js";
import { login } from "../modules/auth";
import { loadIdentity } from "../modules/identity";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      auth: false,
      disableSignInButton: false,
      error: "",
      guideMode: false,
    };

    this.initError.bind(this);
  }

  initError = () => {
    this.setState({ error: "" });
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().guideMode !== this.state.guideMode) {
        this.setState({ guideMode: store.getState().guideMode });
      }
      if (store.getState().animationStep === 3) {
        this.connect(document.getElementById("address_id").value, "/add");
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  async connect(address, route) {
    const signer = store.getState().signer;

    if (!address) {
      this.setState({ error: "Please enter an identity address" });
      store.dispatch({ type: "setAnimationStep", animationStep: 31 });
      return;
    }

    let identity = null;

    try {
      if (!address.startsWith("0x") || address.length !== 42) {
        const response = await store
          .getState()
          .ensContract.getAddressFromName(address);

        if (response === "0x0000000000000000000000000000000000000000") {
          this.setState({
            error: "Please enter a valid name or identity address",
          });
          return;
        }
        address = response;
      }

      identity = await loadIdentity(address, store.getState().provider, signer);
    } catch (error) {
      console.error(error);
      this.setState({ error: "Address doesn't exist" });
      return;
    }

    const walletPublicKey = signer.getAddress();

    let publicKeyMessage = "";
    try {
      //Check if the user has the private and public key of the wallet
      let message = "I want to log into this website";
      let signature = await signer.signMessage(message);
      publicKeyMessage = ethers.utils.verifyMessage(message, signature);
    } catch (error) {
      console.error(error);
      this.setState({
        error: "The authentification part with MetaMask has been cancelled",
      });
      store.dispatch({ type: "setAnimationStep", animationStep: 21 });
      return;
    }

    store.dispatch({
      type: "setAnimationStep",
      animationStep: store.getState().animationStep + 1,
    });

    const publicKeyMatching = walletPublicKey === publicKeyMessage;

    //Check if the user is having the public key corresponding to one of the management key of the address
    const hashedUser = IdentitySDK.utils.encodeAndHash(
      ["address"],
      [await walletPublicKey]
    );
    const authorized = await identity.keyHolderInstance.keyHasPurpose(
      hashedUser,
      IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
    );
    if (!authorized && publicKeyMatching) {
      this.setState({
        error: "You don't have the management key for this identity",
      });
      return;
    }

    if (this.state.guideMode) {
      this.setState({ disableSignInButton: true });
      store.dispatch({ type: "setNextPage", nextPage: route });
    } else {
      login(identity.address);
      this.props.history.push(route);
    }
  }

  handleConnect(address_id, route) {
    store.dispatch({ type: "setHelperState", helperState: "login" });
    store.dispatch({ type: "setAnimationStep", animationStep: 1 });

    if (!store.getState().guideMode) {
      this.connect(document.getElementById("address_id").value, route);
    }
  }

  render() {
    return (
      <div className="Login">
        <div className="max-w-xl mx-auto my-10 bg-white p-7 rounded-md shadow-md">
          <div className="text-center">
            <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
              Login with your identity
            </h1>
            <p className="text-gray-400 dark:text-gray-400">
              Identify with your identity address to add authentication methods
              to your decentralized identity
            </p>
          </div>
          <div className="my-7 mb-6">
            <label
              htmlFor="address_id"
              className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
            >
              Identity address
            </label>
            <input
              type="text"
              name="address_id"
              id="address_id"
              placeholder="Enter your identity adress or your alias here"
              className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                              focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                              dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                              dark:focus:border-gray-500"
            />
          </div>
          <div className="mb-6">
            <button
              onClick={() =>
                this.handleConnect(
                  document.getElementById("address_id").value,
                  "/manage"
                )
              }
              className={`w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none ${
                this.state.disableSignInButton && "cursor-default opacity-30"
              }`}
              disabled={this.state.disableSignInButton}
            >
              Begin to manage your identity
            </button>
          </div>
        </div>
        <div className="mx-auto text-center max-w-xl">
          <p>
            If you do not have any identity, please create your first identity by clicking{" "}
            <a className="text-blue-700 underline" href="/signup">
              here
            </a>
            .
          </p>
        </div>
        <div className="max-w-xl mx-auto my-10">
          {this.state.error && (
            <ClosingAlert
              color="red"
              boldText="Whoops !"
              text={this.state.error}
              onClose={this.initError}
            ></ClosingAlert>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(Login);
