import React from "react";
import { withRouter } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import store from "../store";
import FIDOHelper from "./FIDOHelper";
import OIDCHelper from "./OIDCHelper";
import GitHubHelper from "./GitHubHelper";

import "./helperStyle.css";

class AddingHelper extends React.Component {
  constructor() {
    super();
    this.state = {
      helperState: store.getState().helperState,
    };
    this.FIDO_HELPER_NAME = "FIDO";
    this.GITHUB_HELPER_NAME = "GitHub";
    this.GOOGLE_HELPER_NAME = "Google";
    this.MICROSOFT_HELPER_NAME = "Microsoft";
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({ helperState: store.getState().helperState });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    return (
      <div className="LoginHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Adding Screen</h1>
          
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={this.state.helperState}
              addEndListener={(node, done) =>
                node.addEventListener("transitionend", done, false)
              }
              classNames="fade"
            >
              <div>
                {this.state.helperState === "start" ? (
                <div>
                  <p className="mx-auto text-justify w-5/6">
                    In here, you can link any of the available authentication methods to your identity.
                  </p>

                  <div className="h-96 w-full text-xl my-12">
                      <p
                        key="before"
                        className="relative top-1/2 transform -translate-y-1/2 text-gray-700"
                      >
                        Click on one of the buttons to activate the flow
                      </p> 
                  </div>
                </div>
                ) : this.state.helperState === this.GITHUB_HELPER_NAME ? (
                  <GitHubHelper />
                ) : this.state.helperState === this.FIDO_HELPER_NAME ? (
                  <FIDOHelper />
                ) : this.state.helperState === this.GOOGLE_HELPER_NAME ? (
                  <OIDCHelper identityProvider="Google" />
                ) : this.state.helperState === this.MICROSOFT_HELPER_NAME && (
                  <OIDCHelper identityProvider="Microsoft" />
                ) }
              </div>
            </CSSTransition>
          </SwitchTransition>
        </section>
      </div>
    );
  }
}

export default withRouter(AddingHelper);
