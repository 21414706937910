import { OAuth2Client } from "google-auth-library";
import jwkToPem from "jwk-to-pem";
import * as jsrsasign from "jsrsasign";
import { KJUR } from "jsrsasign";

async function validateJWTGoogle(jwtData, client_id) {
  const client = new OAuth2Client(client_id);
  try {
    const ticket = await client.verifyIdToken({
      idToken: jwtData,
      audience: client_id,
    });
    const payload = ticket.getPayload();
    const userGoogleid = payload["sub"];
    return true;
  } catch (error) {
    console.error(error);
    return;
  }
}

async function validateJWTMicrosoft(jwt) {
  const headerObj = KJUR.jws.JWS.readSafeJSONString(
    jsrsasign.b64utoutf8(jwt.split(".")[0])
  );
  const kid = headerObj.kid;
  return fetch(
    `https://login.microsoftonline.com/common/v2.0/.well-known/openid-configuration`
  ).then(async (response) => {
    return response.json().then(async (json) => {
      const jwks_uri = json.jwks_uri;
      return fetch(jwks_uri).then(async (response) => {
        return response.json().then((json) => {
          const jwk = json.keys.filter((jwk) => jwk.kid === kid)[0];
          const pem = jwkToPem(jwk);

          const isValid = KJUR.jws.JWS.verifyJWT(jwt, pem, {
            alg: ["RS256"],
          });
          return isValid;
        });
      });
    });
  });
}

export { validateJWTGoogle, validateJWTMicrosoft };
