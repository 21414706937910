import React from "react";
import { withRouter } from "react-router-dom";

import store from "../store";

class ManageHelper extends React.Component {
  constructor() {
    super();
    this.state = {
      animationStep: 0,
      helperState: store.getState().helperState,
    };
    this.nextStep.bind(this);
    this.completeLogin.bind(this);
  }

  completeLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "start" });

    this.props.history.push(store.getState().nextPage);
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().helperState === "login") {
        this.setState({ animationStep: 1 });
      }
      this.setState({ helperState: store.getState().helperState });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  infoText() {
    switch (this.state.animationStep) {
      case 0:
        return "Sign in to trigger the explanation";
      case 1:
        return "The browser verify if the wallet exists...";
      case 2:
        return "...and if the identity exists with the input address";
      case 3:
        return "The user prove that he/she is the owner of the wallet by signing the message";
      case 4:
        return "Ask to the blockchain to verify that the user is the owner the identity";
      case 5:
        return "You are now connected!";
      default:
        console.error("Error animation step helper");
    }
  }

  nextStep = () => {
    const animationStepLocal = this.state.animationStep;
    this.setState({ animationStep: animationStepLocal + 1 });
  };

  render() {
    return (
      <div className="LoginHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Manage Screen</h1>
          <div className="mx-auto text-justify w-5/6">
            <p>
              Using this screen, you are able to see all the authentication
              methods that you previously allowed. Each methods allowed is
              stocked on the blockchain, as a claim.
            </p>
            <br/>
            <p>
              As the owner of the claims, you have the right to revoke each claim that is in this page.
              To do that, you just have to click on the "exit" icon. The claim will still exist on the blockchain, 
              but you will not be able to use the claim anymore. 
            </p>
            <p>
              If you want to update the claim, you just have to add a new claim and it will replace this ones.
              You can also delete the claim and then add an other one to replace the informations contains into it.
            </p>
            <br/>
            <p>Each claim has the same data :</p>
            <ul className="list-disc">
              <li>A unique identifiant</li>
              <li>The address of the owner of the claim</li>
              <li>An URI that is not used in our case</li>
              <li>The address of the issuer of the claim</li>
              <li>
                A signature that can be used to prove that the claim is
                authentic
              </li>
              <li>The scheme that is not used in our case</li>
              <li>
                And the public data that is stored in the claim. It is convert
                in hexadecimal.
              </li>
            </ul>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(ManageHelper);
