import React, { Component } from "react";

import GoogleLogin from "./GoogleLogin";
import { config } from "../../config";

export default class GoogleButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
    };

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  componentDidMount() {
    this.loadScript(
      document,
      "script",
      "google-login",
      "https://apis.google.com/js/platform.js",
      () => {
        const params = {
          client_id: config["googleClientId"],
          scope: "email profile openid",
        };

        window.gapi.load("auth2", () => {
          const GoogleAuth = window.gapi.auth2.getAuthInstance();
          if (!GoogleAuth) {
            window.gapi.auth2.init(params);
          }
        });
      },
      (err) => {
        console.error(err);
        //onLoadFailure(err)
      }
    );
  }

  loadScript(doc, balise, id, jsSrc, onSuccess, onError) {
    const element = doc.getElementsByTagName(balise)[0];
    const fjs = element;
    let js = element;
    js = doc.createElement(balise);
    js.id = id;
    js.src = jsSrc;
    if (fjs && fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    } else {
      doc.head.appendChild(js);
    }
    js.onerror = onError;
    js.onload = onSuccess;
  }

  login(response) {
    const ID_ACCOUNT = response.Aa;
    if (ID_ACCOUNT) {
      this.props.onLoggedIn(response);
      this.setState({
        isLogined: true,
        idAccount: ID_ACCOUNT,
      });
    }
  }

  handleLoginFailure(error) {
    console.error(error);
    alert("Failed to log in");
  }

  render() {
    return (
      <div className="flex flex-col items-center">
        <GoogleLogin
          onSuccess={this.login}
          onFailure={this.handleLoginFailure}
        />
        <div>
          {this.state.idAccount && (
            <div className="overflow-clip overflow-hidden">
              Your ID Account: {this.state.idAccount}
            </div>
          )}
        </div>
      </div>
    );
  }
}
