import defaultConfig from "./config/default.json";
import development from "./config/development.json";
import develop from "./config/develop.json";
import netlify from "./config/netlify.json";
import production from "./config/production.json";
import staging from "./config/staging.json";

function setEnvironmentFile() {
  const environment = process.env.REACT_APP_NODE_ENV ? process.env.REACT_APP_NODE_ENV : 'development';
  switch (environment) {
    case "develop":
      return develop;
    case "development":
      return development;
    case "staging":
      return staging;
    case "netlify":
      return netlify;
    case "production":
      return production;
    default:
      return develop;
  }
}

function setVariables() {
  let finalConfig = defaultConfig;
  let envFile = setEnvironmentFile();
  for (let [key, value] of Object.entries(envFile)) {
    finalConfig[key] = value;
  }
  finalConfig["googleClientId"] = process.env.REACT_APP_GOOGLE_CLIENT_ID;
  finalConfig["msalConfig"] = {
    auth: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENT_ID,
      authority: "https://login.microsoftonline.com/common",
      redirectUri: finalConfig["hostname_route"],
    },
    cache: {
      cacheLocation: "localStorage", 
      storeAuthStateInCookie: false,
    },
  };
  return finalConfig;
}

export const config = setVariables();

