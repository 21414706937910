import React from "react";
import { withRouter } from "react-router-dom";

import store from "../store";

class SignupHelper extends React.Component {
  constructor() {
    super();
    this.state = {
      animationStep: 0,
      helperState: store.getState().helperState,
    };
    this.nextStep.bind(this);
    this.completeSignup.bind(this);

    this.NUMBER_STEP = 4;
  }

  completeSignup = () => {
    store.dispatch({ type: "setAnimationStep", animationStep: 0 });
    store.dispatch({ type: "setHelperState", helperState: "start" });
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().helperState !== this.state.helperState) {
        this.setState({ helperState: store.getState().helperState });
      }
      if (store.getState().animationStep !== this.state.animationStep) {
        this.setState({ animationStep: store.getState().animationStep });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  infoText() {
    switch (this.state.animationStep) {
      case 0:
        return "Sign up to trigger the explanation";
      case 1:
        return "The browser prepare the transaction that will create the identity";
      case 2:
        return "Once the user filled in the informations, the wallet sign the transaction when the user has accepting it";
      case 3:
        return "The browser send it to the blockchain for creation";
      case 4:
        return "Congratulations! You just created your new identity on the blockchain.";
      default:
        console.error("Error animation step helper");
    }
  }

  nextStep = () => {
    store.dispatch({
      type: "setAnimationStep",
      animationStep: store.getState().animationStep + 1,
    });
  };

  render() {
    return (
      <div className="SignupHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Sign up Screen</h1>
          <p className="mx-auto text-justify w-5/6">
            Your wallet public address should appear on the input. If you don't
            have any, try to connect a wallet to your browser, and reload the
            page. Then, click on the <b> "Create a new identity"</b> button.
          </p>

          <div className="h-96 w-full text-xl my-12">
            {this.state.helperState === "start" && (
              <p
                key="before"
                className="relative top-1/2 transform -translate-y-1/2 text-gray-700"
              >
                {this.infoText()}
              </p>
            )}
            {this.state.helperState === "signup" &&
            this.state.animationStep < this.NUMBER_STEP && (
              <div key="animation" className="relative text-md">
                <div className="grid grid-rows-4 grid-cols-5 mx-7">
                  <div className="col-span-5 self-center">
                    {this.infoText()}
                  </div>

                  <div className="flex flex-col self-center">
                    <i className="fas fa-desktop text-5xl text-purple-600"></i>
                    <p>Frontend</p>
                  </div>
                  
                  <div className="flex col-span-1 items-stretch">
                    <div
                      className={`h-0 border-purple-600 border-4 rounded-xl self-center w-full duration-1000 transition-opacity ${
                        this.state.animationStep === 2
                          ? "opacity-80"
                          : "opacity-30"
                      }`}
                    ></div>
                  </div>
                  <div className="row-start-2 col-start-3">
                    <i className="fas fa-wallet text-5xl text-purple-600"></i>
                    <p>Wallet</p>
                  </div>
                  <div className="flex col-span-1 items-stretch">
                    <div
                      className={`h-0 border-purple-600 border-4 rounded-xl self-center w-full duration-1000 transition-opacity ${
                        this.state.animationStep === 3
                          ? "opacity-80"
                          : "opacity-30"
                      }`}
                    ></div>
                  </div>
                  <div className="flex flex-col self-center">
                    <i className="fas fa-link text-5xl text-purple-600"></i>
                    <p>Blockchain</p>
                  </div>                  
                </div>

                {this.state.helperState === "signup" &&
                  this.state.animationStep !== 2 && (
                    <div className="flex justify-end mx-12">
                      <button
                        onClick={this.nextStep}
                        className="outline-none text-gray-700 hover:text-purple-600 transition-colors hover:font-bold"
                      >
                        Next {">"}
                      </button>
                    </div>
                  )}
              </div>
            )}
            {this.state.helperState !== "start" &&
            this.state.animationStep >= this.NUMBER_STEP && (
              <div key="connected" className="relative top-24">
                <i
                  className="fas fa-check-circle text-green-400"
                  style={{ fontSize: "7rem" }}
                ></i>
                <p className="mt-4 mb-12">{this.infoText()}</p>
                <button
                  className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                  type="button"
                  onClick={this.completeSignup}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(SignupHelper);
