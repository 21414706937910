import React, { Component } from "react";

import store from "../../store";

import "./GitHubButton.css";

export default class GitHubLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLogined: false,
    };

    this.handleLoginFailure = this.handleLoginFailure.bind(this);
  }

  async componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (
        store.getState().guideMode &&
        store.getState().helperState === "GitHub" &&
        store.getState().animationStep === 1
      ) {
        this.login();
      }
    });
  }

  componentWillUnmount(){
    this.unsubscribe();
  }

  handleClick() {
    if (store.getState().guideMode) {
      store.dispatch({ type: "setHelperState", helperState: "GitHub" });
      store.dispatch({ type: "setAnimationStep", animationStep: 0 });
    } else {
      store.dispatch({ type: "setAnimationStep", animationStep: 0 });
      this.login();
    }
  }

  async login() {
    const username = document.getElementById("github_username").value;
    if (!username) return;
    
    const res = await fetch(`https://api.github.com/users/${username}`, {
      method: "GET",
    }).then(async (response) => await response.json());

    res && res.message !== "Not Found" ? this.props.onSuccess(res) : store.dispatch({ type: "setAnimationStep", animationStep: 10 });
  }

  handleLoginFailure(error) {
    console.error(error);
    alert("Failed to log in");
  }

  render() {
    return (
      <div className="flex flex-col items-center">
        <input
          type="text"
          name="github_username"
          id="github_username"
          placeholder="Enter your username here"
          className="w-3/5 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                              focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                              dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                              dark:focus:border-gray-500"
        />
        <button className="bg-black text-white p-2 px-5 m-2 buttonGithub" onClick={this.handleClick.bind(this)}>
          <div className="divGithub">
            <svg
              className="fill-current text-white"
              height="20"
              viewBox="0 0 16 16"
              version="1.1"
              width="20"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
              ></path>
            </svg>
          </div>
          <span className="spanGithub">Add GitHub Key</span>
        </button>
      </div>
    );
  }
}
