import React from "react";

const Alert = ({ color, text, boldText, closing }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  return (
    <>
      {showAlert && (
        <div
          className={
            "text-white px-6 py-4 border-0 rounded text-center relative mb-4 bg-" +
            color +
            "-500"
          }
        >
          <span className="inline-block align-middle mr-8">
            <b className="capitalize">{boldText}</b> {text}
          </span>
          <button
            className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-4 mr-6 outline-none focus:outline-none"
            onClick={() => { if (closing){closing()}; setShowAlert(false)}}
          >
            <span>×</span>
          </button>
        </div>
      )}
    </>
  );
};

export default function ClosingAlert(props) {
  return (
    <>
      <Alert color={props.color} text={props.text} boldText={props.boldText} closing={props.onClose}/>
    </>
  );
}
