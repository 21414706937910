import React from "react";
import { withRouter } from "react-router-dom";

import WebAuthnLogin from "../Buttons/WebAuthn/WebAuthnLogin.js";
import { publicKeyCredentialToJSON } from "../modules/fido2";

class Signature extends React.Component {
  constructor(props) {
    super(props);

    this.state = { error: "" };

    const query = new URLSearchParams(window.location.search);
    this.storedAddress = query.get("address");
    this.challenge = query.get("challenge");
    this.credentialId = query.get("credentialId");
    this.handleFIDOLogIn = this.handleFIDOLogIn.bind(this);
  }

  async componentDidMount() {}

  async handleFIDOLogIn(assertion) {
    this.sendMessage(assertion);
  }

  handleLoginFailure() {
    alert("Failed to log in");
  }

  sendMessage(assertion) {
    let res = {};
    res.source = "portal-ONCHAINID";
    if (assertion) {
      res.payload = publicKeyCredentialToJSON(assertion);
    }
    if (window.opener) {
      // send the key to the opening window
      window.opener.postMessage(res, "*");
      // close the popup
      window.close();
    }
  }

  render() {
    return (
      <div className="h-screen text-center">
        <div className="m-auto max-w-2xl bg-white border-1 border-gray-300 p-6 tracking-wide shadow-lg">
          <div className="my-12">
            <h1 className="my-3 text-2xl font-semibold text-gray-700 dark:text-gray-200">
              Use your fingerprint
            </h1>
            <div className="my-3 text-l text-gray-700 dark:text-gray-200">
              in order to connect with FIDO
            </div>
            <div>
              <div className="m-2">
                <WebAuthnLogin
                  challenge={this.challenge}
                  userAddress={this.storedAddress}
                  credentialId={this.credentialId}
                  onSuccess={this.handleFIDOLogIn}
                  onFailure={this.handleLoginFailure}
                />
              </div>
            </div>
          </div>
        </div>
        <p>{this.state.error}</p>
      </div>
    );
  }
}

export default withRouter(Signature);
