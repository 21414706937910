import ONCHAINID from "@onchain-id/solidity";
import { ethers } from "ethers";
import React from "react";
import { Link } from "react-router-dom";

import store from "../store";
import ClosingAlert from "../Alerts/ClosingAlert.js";
import Spinner from "../Spinner/Spinner";

class Signup extends React.Component {
  constructor() {
    super();
    this.state = {
      identity: null,
      accountAddress: null,
      waintingForNewIdentity: false,
      error: "",
    };

    this.identity = null;
  }

  componentDidMount() {
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((addresses) => {
        return this.setState({ accountAddress: addresses[0] });
      })
      .then(() => {
        if (!this.state.accountAddress) {
          this.setState({
            error:
              "Metamask is not connected. Please connect and reload this page.",
          });
        }
      });

    window.ethereum.on("accountsChanged", async (accounts) => {
      this.setState({ identity: null });
      if (accounts[0]) this.setState({ accountAddress: accounts[0] });
      else {
        this.setState({ accountAddress: null });
        this.setState({
          error:
            "Metamask is not connected. Please connect and reload this page.",
        });
      }
    });

    this.unsubscribe = store.subscribe(async () => {
      if (store.getState().guideMode && store.getState().animationStep === 4) {
        this.setState({ identity: this.identity });
      }
      if (store.getState().guideMode && store.getState().animationStep === 3) {
        await this.identity.deployed();
        this.setState({ waintingForNewIdentity: false });
      }
      if (store.getState().guideMode && store.getState().animationStep === 2) {
        await this.generateIdentity();
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  initError = () => {
    this.setState({ error: "" });
  };

  async signUp() {
    store.dispatch({ type: "setHelperState", helperState: "signup" });
    store.dispatch({ type: "setAnimationStep", animationStep: 1 });

    store.getState().guideMode
      ? this.setState({ disableSignupButton: true })
      : this.generateIdentity();
  }

  async generateIdentity() {
    const signer = store.getState().signer;
    try {
      const identityFactory = new ethers.ContractFactory(
        ONCHAINID.contracts.Identity.abi,
        ONCHAINID.contracts.Identity.bytecode,
        signer
      );
      const identity = await identityFactory.deploy(
        await signer.getAddress(),
        false
      );
      this.identity = identity;
      store.dispatch({
        type: "setAnimationStep",
        animationStep: store.getState().animationStep + 1,
      });
      this.setState({ waintingForNewIdentity: true });
      if (!store.getState().guideMode) {
        await identity.deployed();
        this.setState({ identity: identity });
        this.setState({ waintingForNewIdentity: false });
      }
    } catch (error) {
      console.error("User cancelled the transaction of the identity");
      this.setState({ disableSignupButton: false });
      store.dispatch({ type: "setAnimationStep", animationStep: 0 });
      store.dispatch({ type: "setHelperState", helperState: "start" });
    }
  }

  render() {
    return (
      <div className="Signup">
        {this.state.waintingForNewIdentity && (
          <Spinner
            title="Your identity is being created!"
            text="Your identity adress should
          appear in some time."
          ></Spinner>
        )}
        {!this.state.identity ? (
          <div className="max-w-xl mx-auto my-10 bg-white p-7 rounded-md shadow-md">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
                Create your identity
              </h1>
              <p className="text-gray-400 dark:text-gray-400">
                Fill up the form below to retrieve a identity address
              </p>
            </div>
            <div className="m-7">
              <div className="mb-6">
                <label
                  htmlFor="address_wallet"
                  className="block mb-2 text-left text-sm text-gray-600 dark:text-gray-400"
                >
                  Wallet address
                </label>
                {this.state.accountAddress && (
                  <p
                    className="w-full text-center px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                                    focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                                    dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                                    dark:focus:border-gray-500"
                  >
                    {this.state.accountAddress}
                  </p>
                )}
              </div>
            </div>
            <div className="mb-6">
              <button
                disabled={
                  !this.state.accountAddress || this.state.disableSignupButton
                }
                onClick={() => this.signUp()}
                className="disabled:opacity-50 disabled:cursor-default w-full px-3 py-4 text-white bg-indigo-500 rounded-md focus:bg-indigo-600 focus:outline-none"
              >
                Create a new identity
              </button>
            </div>
          </div>
        ) : (
          <div className="max-w-xl mx-auto my-10 bg-white p-7 rounded-md shadow-md">
            <div className="text-center">
              <h1 className="my-3 text-3xl font-semibold text-gray-700 dark:text-gray-200">
                Success !
              </h1>
              <p className="text-gray-400 dark:text-gray-400">
                Please find your new identity address below
              </p>
            </div>

            <div className="m-7 mb-6">
              <p
                className="w-full text-center px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                              focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                              dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                              dark:focus:border-gray-500"
              >
                {this.state.identity.address}
              </p>
            </div>

            <div className="text-center">
              <p className="">
                This address is really important. It is the only way to get your
                identity. Keep it preciously, because there is no way to get it
                back if it is lost.
              </p>
            </div>
          </div>
        )}
        <div className="flex justify-center">
          <Link
            to="/login"
            className="bg-red-300 text-white focus:bg-red-600 focus:outline-none rounded w-full sm:w-auto px-5 py-2 m-5"
          >
            Back to login
          </Link>
        </div>
        <div className="max-w-xl mx-auto my-10">
          {this.state.error && (
            <ClosingAlert
              color="red"
              boldText="Whoops !"
              text={this.state.error}
              onClose={this.initError}
            ></ClosingAlert>
          )}
        </div>
      </div>
    );
  }
}

export default Signup;
