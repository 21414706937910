import { ethers } from "ethers";
import React from "react";
import { Switch, Redirect, withRouter } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import "./App.css";

import { logout } from "../modules/auth";
import { config } from "../config";
import store from "../store";
import Helper from "../Helpers/Helper";
import Login from "../Login/Login.js";
import MethodsManagement from "../MethodsManagement/MethodsManagement";
import MethodsAdding from "../MethodsAdding/MethodsAdding";
import PublicRoute from "../Routes/PublicRoute";
import PrivateRoute from "../Routes/PrivateRoute";
import Signup from "../Signup/Signup";
import Signature from "../Signature/Signature";
import NoMetaMaskDetected from "../Errors/NoMetaMaskDetected/NoMetaMaskDetected";
import NoMetaMaskConnection from "../Errors/NoMetaMaskConnection/NoMetaMaskConnection";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      isConnected: false,
      guideMode: false,
    };

    if (window.sessionStorage.getItem(config["session_storage_id_address"])) {
      store.dispatch({ type: "setIsConnected", isConnected: true });
      this.state.isConnected = true;
    }

    this.setPopup = this.setPopup.bind(this);
  }

  async componentDidMount() {
    if (!window.ethereum) {
      this.props.history.push("/noprovider");
    } else {
      const response = window.ethereum.request({
        method: "eth_requestAccounts",
      });

      if (!response) {
        this.props.history.push("/noproviderconnection");
      }

      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      store.dispatch({ type: "setProvider", provider: provider });
      store.dispatch({ type: "setSigner", signer: signer });

      //Load the ENS contract that will be use later
      const abi = [
        "function isNameAvailable(string memory name) external view returns(bool isAvailable)",
        "function getAddressFromName(string calldata name) external view returns(address addr)",
        "function getNameFromAddress(address addr) external view returns(string memory name)",
        "function setName(string memory name, address addr) external",
        "function remove(string memory name) external",
      ];
      const erc20 = new ethers.Contract(
        "0x3F17eca5536D85a92736E4CEf2A150A924064C20",
        abi,
        signer
      );
      store.dispatch({ type: "setEnsContract", ensContract: erc20 });

      await this.connectToAnMetaMaskAccount();
      await this.setNetwork();
    }

    this.unsubscribe = store.subscribe(async () => {
      if (store.getState().isConnected !== this.state.isConnected) {
        this.setState({ isConnected: store.getState().isConnected });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  async connectToAnMetaMaskAccount() {
    await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .catch((error) => {
        this.props.history.push("/noproviderconnection");
      });
  }

  async setNetwork() {
    await window.ethereum
      .request({
        method: "wallet_addEthereumChain",
        params: [config["network"]],
      })
      .catch(console.error);
  }

  setPopup() {
    const reverse = !this.state.guideMode;
    store.dispatch({ type: "setGuideMode", guideMode: reverse });
    this.setState({ guideMode: reverse });
  }

  render() {
    let AppWidth = "transition-width duration-700";
    this.state.guideMode ? (AppWidth += " w-2/3") : (AppWidth += " w-full");
    let classNameHelper =
      "fixed top-0 right-0 bg-gray-100 overflow-hidden mx-auto w-1/3 h-screen";
    return (
      <div className={"App" + AppWidth}>
        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.2.0/css/all.css"
          integrity="sha384-hWVjflwFxL6sNzntih27bfxkr27PmbbK/iSvJ+a4+0owXq79v+lsFkW54bOGbiDQ"
          crossOrigin="anonymous"
        ></link>
        <header className="App-header">
          <p>POC Authentication IdentInTech</p>
          {this.state.isConnected && (
            <button
              className="z-40 right-3 absolute text-base bg-gray-400 hover:bg-grey-600 text-white font-bold py-2 px-4"
              onClick={logout}
            >
              Logout
            </button>
          )}
        </header>
        <div className="text-center">
          <small>
            You are running this application in{" "}
            <b>{process.env.REACT_APP_NODE_ENV}</b> mode.
          </small>
        </div>
        <button
          className="fixed z-40 mx-10 text-green-400 hover:text-green-600 right-3 focus:outline-none"
          onClick={this.setPopup}
        >
          {!this.state.guideMode ? "Start" : "Close"} Manual
        </button>
        <Switch>
          <PublicRoute
            restricted={true}
            component={Login}
            path="/login"
            exact
          />
          <PublicRoute
            restricted={true}
            component={Signup}
            path="/signup"
            exact
          />
          <PublicRoute
            restricted={false}
            component={Signature}
            path="/signature"
            exact
          />
          <PublicRoute
            restricted={false}
            component={NoMetaMaskDetected}
            path="/noprovider"
            exact
          />
          <PublicRoute
            restricted={false}
            component={NoMetaMaskConnection}
            path="/noproviderconnection"
            exact
          />
          <PrivateRoute component={MethodsAdding} path="/add" exact />
          <PrivateRoute component={MethodsManagement} path="/manage" exact />
          <Redirect from="*" to="/login" />
        </Switch>
        <CSSTransition
          in={this.state.guideMode}
          timeout={700}
          classNames="guide"
          unmountOnExit
        >
          <div className={classNameHelper}>
            <Helper guideMode={this.state.guideMode} />
          </div>
        </CSSTransition>
      </div>
    );
  }
}

export default withRouter(App);
