import base64url from "base64url";
import CBOR from "cbor-js";
import React, { Component } from "react";

import WebAuthnRegister from "./WebAuthnRegister";
import { config } from "../../config";

export default class WebAuthnButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      registeredFidoId: "",
    };
    this.storedAddress = window.sessionStorage.getItem(
      config["session_storage_id_address"]
    );

    this.register = this.register.bind(this);
  }

  decodeAttestationObj(credential) {
    const decodedAttestationObject = CBOR.decode(
      credential.response.attestationObject
    );

    const { authData } = decodedAttestationObject;

    // get the length of the credential ID
    const dataView = new DataView(new ArrayBuffer(2));
    const idLenBytes = authData.slice(53, 55);
    idLenBytes.forEach((value, index) => dataView.setUint8(index, value));
    const credentialIdLength = dataView.getUint16();

    // get the credential ID
    const credentialId = authData.slice(55, 55 + credentialIdLength);

    // get the public key object
    const publicKeyBytes = authData.slice(55 + credentialIdLength);

    // the publicKeyBytes are encoded again as CBOR
    const publicKeyObject = CBOR.decode(publicKeyBytes.buffer);

    const resAuthData = {
      credentialIdLength: credentialIdLength,
      credentialId: base64url.encode(credentialId),
      publicKeyBytes: base64url.encode(publicKeyBytes),
      publicKeyObject: publicKeyObject,
    };

    return resAuthData;
  }

  async register(response) {
    const credential = response;
    const authData = this.decodeAttestationObj(credential);
    credential.authData = authData;

    if (credential.id) {
      this.setState({
        registeredFidoId: credential.id,
      });
      this.props.onLoggedIn(credential);
    }
  }

  handleRegisterFailure() {
    alert("Failed to register");
  }

  render() {
    return (
      <div>
        <WebAuthnRegister
          userID={this.storedAddress}
          onSuccess={this.register}
          onFailure={this.handleRegisterFailure}
        ></WebAuthnRegister>
        {this.state.registeredFidoId && (
          <div className="truncate text-center">
            Your ID:
            <br /> {this.state.registeredFidoId}
          </div>
        )}
      </div>
    );
  }
}
