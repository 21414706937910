import { ethers } from "ethers";
import React from "react";

import { config } from "../../config";
import store from "../../store";
import { loadIdentity } from "../../modules/identity";
import Spinner from "../../Spinner/Spinner";

class MethodsManagement extends React.Component {
  constructor() {
    super();
    this.state = {
      identity: null,
      value: null,
      claims: [],
      updateIdentityName: false,
      waintingForNewIdentityName: false,
      waintingForIdentityDeletion: false,
      error: "",
    };
  }

  async componentDidMount() {
    this.provider =
      store.getState().provider ||
      new ethers.providers.Web3Provider(window.ethereum);
    this.signer = store.getState().signer || this.provider.getSigner();
    this.storedAddress = window.sessionStorage.getItem(
      config["session_storage_id_address"]
    );

    try {
      this.identity = await loadIdentity(
        this.storedAddress,
        this.provider,
        this.signer
      );
      this.setState({ identity: this.identity });
    } catch (error) {
      console.error(error);
      return;
    }
    this.getAlias();
  }

  async createOrUpdateIdentityName() {
    this.setState({ error: "" });
    const name = document.getElementById("name_ENS").value;
    this.setState({
      waintingForNewIdentityName: true,
      updateIdentityName: true,
    });
    try {
      if (name === this.state.alias) {
        this.setState({
          alias: name,
          updateIdentityName: false,
          waintingForNewIdentityName: false,
        });
      } else if (await store.getState().ensContract.isNameAvailable(name)) {
        const tx = await store
          .getState()
          .ensContract.setName(name, this.identity.address);
        await tx.wait();
        await this.getAlias();
        this.toggleUpdateIdentityName();
      } else {
        this.setState({
          waintingForNewIdentityName: false,
          error: "This alias is not available",
        });
      }
    } catch (e) {
      console.error(e);
      await this.getAlias();
      this.toggleUpdateIdentityName();
      this.setState({
        error: "There has been a problem, please retry later",
      });
    }
  }

  async getAlias() {
    try {
      const name = await store
        .getState()
        .ensContract.getNameFromAddress(this.identity.address);
      this.setState({ alias: name });
    } catch (e) {
      console.error(e);
    }
  }

  toggleUpdateIdentityName() {
    if (!this.state.updateIdentityName === false) {
      this.setState({
        waintingForNewIdentityName: false,
      });
    }
    this.setState({
      updateIdentityName: !this.state.updateIdentityName,
      error: "",
    });
  }

  async giveUpIdentityName() {
    try {
      if (this.state.alias) {
        const tx = await store.getState().ensContract.remove(this.state.alias);
        this.setState({ waintingForIdentityDeletion: true });
        await tx.wait();
        this.setState({ alias: "" });
      }
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ waintingForIdentityDeletion: false });
    }
  }

  render() {
    return (
      <div className="ENS_management">
        {this.state.waintingForNewIdentityName && (
          <Spinner
            className="hidden spinner"
            title="Your identity name is being changed!"
            text="The new name should
          appear in some time."
          ></Spinner>
        )}
        {this.state.waintingForIdentityDeletion && (
          <Spinner
            className="hidden spinner"
            title="Your identity name is being deleted!"
            text="Your alias should
          disappear in some time."
          ></Spinner>
        )}
        {this.state.identity && (
          <div className="max-w-3xl mx-auto my-10 bg-white p-10 rounded-md shadow-md text-left">
            <div className="divide-y divide-light">
              <div className="p-4">
                <p className="text-gray-400 dark:text-gray-400">
                  Your identity address
                </p>
                <h1 className="text-md lg:text-2xl font-semibold text-gray-700 dark:text-gray-200 overflow-hidden overflow-ellipsis">
                  {this.identity.address}
                </h1>
              </div>
              <div className="p-4">
                <p className="text-gray-400 dark:text-gray-400">Your alias</p>
                {this.state.alias && !this.state.updateIdentityName ? (
                  <>
                    <div className="flex">
                      <h1 className="w-3/4 text-md lg:text-2xl font-semibold text-gray-700 dark:text-gray-200 overflow-hidden overflow-ellipsis">
                        {this.state.alias}
                      </h1>
                      <div className="w-1/4 ml-2 flex">
                        <div className={`w-2/4 px-3 py-2`}></div>
                        <button
                          onClick={() => this.toggleUpdateIdentityName()}
                          className={`w-1/4 px-3 py-2 text-white bg-purple-500 rounded-md focus:bg-purple-600 focus:outline-none`}
                        >
                          <i className="fas fa-pen"></i>
                        </button>
                        <button
                          onClick={() => this.giveUpIdentityName()}
                          className={`w-1/4 ml-1 px-3 py-2 text-white bg-red-500 rounded-md focus:bg-red-600 focus:outline-none`}
                        >
                          <i className="far fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="flex">
                      <input
                        type="text"
                        name="name_ENS"
                        id="name_ENS"
                        placeholder="Create a new alias"
                        defaultValue={this.state.alias}
                        className="w-3/4 mr-2 px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md 
                                    focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300 
                                    dark:bg-gray-700 dark:text-white dark:placeholder-gray-500 dark:border-gray-600 dark:focus:ring-gray-900 
                                    dark:focus:border-gray-500"
                      />
                      <div className="w-1/4 ml-2 flex">
                        <button
                          onClick={() => this.createOrUpdateIdentityName()}
                          className={`w-3/4 px-3 py-2 text-white bg-purple-500 rounded-md focus:bg-purple-600 focus:outline-none`}
                        >
                          <i className="fas fa-check"></i>
                        </button>
                        <button
                          onClick={() => this.toggleUpdateIdentityName()}
                          className={`w-1/4 ml-1 px-3 py-2 text-white bg-red-500 rounded-md focus:bg-red-600 focus:outline-none`}
                        >
                          <i className="fas fa-times"></i>
                        </button>
                      </div>
                    </div>
                  </>
                )}
                {this.state.error && (
                  <p className="text-red-400">{this.state.error}</p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default MethodsManagement;
