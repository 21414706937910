import * as msal from "@azure/msal-browser";
import React from "react";

import MicrosoftLogin from "./MicrosoftLogin";
import MicrosoftLogout from "./MicrosoftLogout";
import { config } from "../../config";

export default class MicrosoftButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLogined: false,
      idAccount: "",
    };

    this.myMSALObj = new msal.PublicClientApplication(config["msalConfig"]);

    this.login = this.login.bind(this);
    this.handleLoginFailure = this.handleLoginFailure.bind(this);
    this.logout = this.logout.bind(this);
    this.handleLogoutFailure = this.handleLogoutFailure.bind(this);
  }

  login(response) {
    const ID_ACCOUNT = response.idTokenClaims.oid;
    if (ID_ACCOUNT) {
      this.props.onLoggedIn(response);
      this.setState({
        isLogined: true,
        idAccount: ID_ACCOUNT,
      });
    }
  }

  logout(response) {
    this.props.onLoggedOut(response);
    this.setState({
      isLogined: false,
      idAccount: "",
    });
  }

  handleLoginFailure() {
    alert("Failed to log in");
  }

  handleLogoutFailure() {
    alert("Failed to log out");
  }

  render() {
    return (
      <div className="flex flex-col">
        {this.state.isLogined ? (
          <MicrosoftLogout
            msalConfig={config["msalConfig"]}
            onLogoutSuccess={this.logout}
            onFailure={this.handleLogoutFailure}
          ></MicrosoftLogout>
        ) : (
          <MicrosoftLogin
            msalConfig={config["msalConfig"]}
            onSuccess={this.login}
            onFailure={this.handleLoginFailure}
          />
        )}

        <div>
          {this.state.idAccount && (
            <div className="overflow-clip overflow-hidden text-center">
              Your ID Account: {this.state.idAccount}
            </div>
          )}
        </div>
      </div>
    );
  }
}
