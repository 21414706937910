import React from "react";
import { withRouter } from "react-router-dom";

import store from "../store";

class LoginHelper extends React.Component {
  constructor() {
    super();
    this.state = {
      animationStep: 0,
      helperState: store.getState().helperState,
    };
    this.nextStep.bind(this);
    this.completeLogin.bind(this);
  }

  completeLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "start" });

    this.props.history.push(store.getState().nextPage);
  };

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().helperState !== this.state.helperState) {
        this.setState({ helperState: store.getState().helperState });
      }
      if (store.getState().animationStep !== this.state.animationStep) {
        this.setState({ animationStep: store.getState().animationStep });
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  infoText() {
    switch (this.state.animationStep) {
      case 0:
        return "Sign in to trigger the explanation";
      case 1:
        return "The browser verify if the wallet exists...";
      case 2:
        return "...and if the identity exists with the input address";
      case 3:
        return "The user prove that he/she is the owner of the wallet by signing the message";
      case 4:
        return "Ask to the blockchain to verify that the user is the owner the identity";
      case 5:
        return "You are now connected!";
      case 11:
        return "There have been a error with MetaMask, please try later";
      case 21:
        return "The authentification part with MetaMask has been cancelled";
      case 31:
        return "Missing identity address";
      default:
        console.error("Error animation step helper");
    }
  }

  nextStep = () => {
    store.dispatch({ type: "setAnimationStep", animationStep:store.getState().animationStep+1 });
  };

  render() {
    return (
      <div className="LoginHelper">
        <section className="mx-auto mt-14 w-full text-center">
          <h1 className="text-3xl mb-12">Login Screen</h1>
          <p className="mx-auto text-justify w-5/6">
            Please enter a valid identity address link to the wallet you are
            currently using. If you don't have any, simply create one by
            clicking on the<b> "Sign up"</b> button.
          </p>

          <div className="h-96 w-full text-xl my-12">
            {this.state.helperState === "start" && (
              <p
                key="before"
                className="relative top-1/2 transform -translate-y-1/2 text-gray-700"
              >
                {this.infoText()}
              </p>
            )}
            {this.state.helperState === "login" &&
            this.state.animationStep < 5 ? (
              <div key="animation" className="relative text-md">
                <div className="grid grid-rows-4 grid-cols-5 mx-7">
                  <div className="col-span-5 self-center">
                    {this.infoText()}
                  </div>

                  <div className="flex flex-col self-center">
                    <i className="fas fa-desktop text-5xl text-purple-600"></i>
                    <p>Frontend</p>
                  </div>
                  <div className="flex col-span-3 items-stretch">
                    <div
                      className={`h-0 border-purple-600 border-4 rounded-xl self-center w-full duration-1000 transition-opacity ${
                        this.state.animationStep === 2 ||
                        this.state.animationStep === 4
                          ? "opacity-80"
                          : "opacity-30"
                      }`}
                    ></div>
                  </div>
                  <div className="flex flex-col self-center">
                    <i className="fas fa-link text-5xl text-purple-600"></i>
                    <p>Blockchain</p>
                  </div>
                  <div
                    className={`col-start-2 h-24 transition-opacity duration-1000 ${
                      this.state.animationStep === 1 ||
                      this.state.animationStep === 3
                        ? "opacity-80"
                        : "opacity-30"
                    }`}
                  >
                    <svg style={{ width: "100%", height: "100%" }}>
                      <line
                        x1="93%"
                        y1="93%"
                        x2="7%"
                        y2="7%"
                        strokeLinecap="round"
                        style={{ stroke: "#7C3AED", strokeWidth: "9" }}
                      />
                    </svg>
                  </div>
                  <div className="row-start-4 col-start-3">
                    <i className="fas fa-wallet text-5xl text-purple-600"></i>
                    <p>Wallet</p>
                  </div>
                </div>

                {this.state.helperState === "login" &&
                  this.state.animationStep !== 3 && (
                    <div className="flex justify-end mx-12">
                      <button
                        onClick={this.nextStep}
                        className="outline-none text-gray-700 hover:text-purple-600 transition-colors hover:font-bold"
                      >
                        Next {">"}
                      </button>
                    </div>
                  )}
              </div>
            ) : (
              ""
            )}
            {this.state.helperState === "login" &&
            (this.state.animationStep > 10) ? (
              <div key="connected" className="relative top-24">
                <i
                  className="fas fa-minus-circle text-red-400"
                  style={{ fontSize: "7rem" }}
                ></i>
                <p className="mt-4 mb-12">{this.infoText() || "There has been an error!"}</p>
                <button
                  className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                  type="button"
                  onClick={this.completeLogin}
                >
                  Continue
                </button>
              </div>
            ) : 
            this.state.helperState === "login" &&
            this.state.animationStep >= 5 && (
              <div key="connected" className="relative top-24">
                <i
                  className="fas fa-check-circle text-green-400"
                  style={{ fontSize: "7rem" }}
                ></i>
                <p className="mt-4 mb-12">You are now connected</p>
                <button
                  className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                  type="button"
                  onClick={this.completeLogin}
                >
                  Continue
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(LoginHelper);
