import base64url from "base64url";
import { v4 as uuidv4 } from "uuid";

async function getPublicKeyCredentialCreationOptions(address) {
  const challenge = uuidv4();

  return {
    challenge: Uint8Array.from(challenge, (c) => c.charCodeAt(0)),
    rp: {
      name: "POC Authentication",
    },
    user: {
      id: Buffer.from(address + Date.now(), "utf-8"),
      name: "",
      displayName: "",
    },
    pubKeyCredParams: [
      {
        type: "public-key",
        alg: -7,
      },
      {
        type: "public-key",
        alg: -257,
      },
    ],
    timeout: 60000,
    attestation: "none",
    authenticatorSelection: {
      authenticatorAttachment: "platform",
      userVerification: "required",
    },
  };
}

async function sendCredentialOptionsToAuthenticator(options) {
  const cred = await navigator.credentials.create({
    publicKey: options,
  });

  return cred;
}

function publicKeyCredentialToJSON(pubKeyCred) {
  if (pubKeyCred instanceof Array) {
    let arr = [];
    for (let i of pubKeyCred) arr.push(publicKeyCredentialToJSON(i));

    return arr;
  }

  if (pubKeyCred instanceof ArrayBuffer) {
    return base64url.encode(pubKeyCred);
  }

  if (pubKeyCred instanceof Object) {
    let obj = {};

    for (let key in pubKeyCred) {
      obj[key] = publicKeyCredentialToJSON(pubKeyCred[key]);
    }

    return obj;
  }

  return pubKeyCred;
}

export {
  getPublicKeyCredentialCreationOptions,
  publicKeyCredentialToJSON,
  sendCredentialOptionsToAuthenticator,
};
