import * as msal from "@azure/msal-browser";
import React from "react";

export default class MicrosoftLogout extends React.Component {
  constructor(props) {
    super(props);

    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    const MSAL_CONFIG = this.props.msalConfig;
    this.myMSALObj = new msal.PublicClientApplication(MSAL_CONFIG);
  }

  logout() {
    this.myMSALObj.logout();
  }

  render() {
    return (
      <div className="mx-auto">
        <button
          onClick={() => this.logout()}
          className="p-2 border-solid border border-black px-5 m-2"
        >
          Sign out from Microsoft
        </button>
      </div>
    );
  }
}
