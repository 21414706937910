import ClosingAlert from "../ClosingAlert";

export default function ClosingInformationAlert(props) {
  return (
    <>
      <ClosingAlert
        color="blue"
        boldText="Some information on your request !"
        text={
          "The claim is being send to the blockchain... Please wait before using " +
          props.method +
          " as an authentication method."
        }
      ></ClosingAlert>
    </>
  );
}
