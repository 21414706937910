import React from "react";
import { withRouter } from "react-router-dom";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import store from "../store";
import "./helperStyle.css";

class GitHubHelper extends React.Component {
  constructor() {
    super();
    this.nextStep.bind(this);
    this.completeLogin.bind(this);
    this.state = { transitionState: "inprogress" };
  }

  async componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      if (store.getState().animationStep < 3)
        this.setState({ transitionState: "inprogress" });
      if (store.getState().animationStep === 9)
        this.setState({ transitionState: "success" });
      if (store.getState().animationStep === 10)
        this.setState({ transitionState: "error" });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  completeLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "start" });
  };

  uncompleteLogin = () => {
    store.dispatch({ type: "setHelperState", helperState: "start" });
  };

  infoText() {
    switch (store.getState().animationStep) {
      case 0:
        return "The front gets your profile from GitHub";
      case 1:
        return "A popup opens with your data to put in a GitHub Gist";
      case 2:
        return "The front gets the public gists contained in your profile";
      case 3:
        return "The credentials are send to the blockchain";
      case 9:
        return "When the previous steps are done, you are registered!";
      case 10:
        return "An error occurred, you are not registered";
      case 20:
        return "You have to create a new public GitHub Gist to continue";
      default:
        console.error(
          "Error animation step helper " + store.getState().animationStep
        );
        return "";
    }
  }

  nextStep = () => {
    store.dispatch({
      type: "setAnimationStep",
      animationStep: store.getState().animationStep + 1,
    });
  };

  render() {
    return (
      <div className="GitHubHelper">
        <p className="mx-auto text-justify w-5/6">
          This tutoriel helps you understand GitHub Registration flow
        </p>
        <div className="h-96 w-full text-xl my-12">
          <SwitchTransition mode="out-in">
            <CSSTransition
              key={this.state.transitionState}
              timeout={500}
              classNames="fade"
              unmountOnExit
            >
              <div>
                {this.state.transitionState === "inprogress" ? (
                  <div key="animation" className="relative text-md">
                    <div className="gap-4 grid grid-rows-5 grid-cols-5 mx-5">
                      <div className="col-span-5 self-center">
                        {this.infoText()}
                      </div>
                      <div className="row-start-2 flex flex-col self-center">
                        <i className="fas fa-desktop text-5xl text-purple-600"></i>
                        <p>Front-end</p>
                      </div>
                      {/* Line from Front-end to Blockchain */}
                      <div className="flex col-span-3 items-stretch">
                        <div
                          className={`h-0 border-purple-600 border-4 rounded-xl self-center w-full duration-1000 transition-opacity ${
                            store.getState().animationStep === 3
                              ? "opacity-80"
                              : "opacity-30"
                          }`}
                        ></div>
                      </div>
                      <div className="flex flex-col self-center">
                        <i className="fas fa-link text-5xl text-purple-600"></i>
                        <p>Blockchain</p>
                      </div>

                      {/* Line from Front-end to GitHub */}
                      <div className="flex items-stretch">
                        <div
                          className={`h-90 border-purple-600 border-4 rounded-xl self-center w-0 h-full my-4 mx-auto duration-1000 transition-opacity ${
                            store.getState().animationStep === 0 || store.getState().animationStep === 2
                              ? "opacity-80"
                              : "opacity-30"
                          }`}
                        ></div>
                      </div>


                      
                      <div className="row-start-4 col-start-1">
                        <i className="fas fa-window-restore text-5xl text-purple-600"></i>
                        <p>GitHub</p>
                      </div>
                    </div>
                    {store.getState().animationStep !== 1 &&
                      store.getState().animationStep !== 3 && (
                        <div className="flex justify-end mx-12">
                          <button
                            onClick={this.nextStep}
                            className="outline-none text-gray-700 hover:text-purple-600 transition-colors hover:font-bold"
                          >
                            Next {">"}
                          </button>
                        </div>
                      )}
                  </div>
                ) : this.state.transitionState === "success" ? (
                  <div key="connected" className="relative top-24">
                    <i
                      className="fas fa-check-circle text-green-400"
                      style={{ fontSize: "7rem" }}
                    ></i>
                    <p className="mt-4 mb-12">{this.infoText()}</p>
                    <button
                      className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                      type="button"
                      onClick={this.completeLogin}
                    >
                      Continue
                    </button>
                  </div>
                ) : (
                  this.state.transitionState === "error" && (
                    <div key="connected" className="relative top-24">
                      <i
                        className="fas fa-times-circle text-red-400"
                        style={{ fontSize: "7rem" }}
                      ></i>
                      <p className="mt-4 mb-12">{this.infoText()}</p>
                      <button
                        className=" bg-intech-primary hover:bg-intech-secondary text-white font-bold py-2 px-4 rounded relative bottom-0"
                        type="button"
                        onClick={this.uncompleteLogin}
                      >
                        Done
                      </button>
                    </div>
                  )
                )}
              </div>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>
    );
  }
}

export default withRouter(GitHubHelper);
