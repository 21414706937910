import { Identity, IdentitySDK } from "@onchain-id/identity-sdk";

async function loadIdentity(identity_address, provider, signer) {
  const identity = await Identity.at(identity_address, signer);
  const keys = await identity.getKeysByPurpose(
    IdentitySDK.utils.enums.KeyPurpose.MANAGEMENT
  );
  for (const key of keys) {
    const hashedAddress = IdentitySDK.utils.encodeAndHash(
      ["address"],
      [await signer.getAddress()]
    );
    if (key.key === hashedAddress) {
      return identity;
    }
  }
}

export { loadIdentity };
