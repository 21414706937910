import { IdentitySDK } from "@onchain-id/identity-sdk";
import { ethers } from "ethers";

function createClaim(address, issuer, data, topic) {
  const claim = new IdentitySDK.Claim({
    address: address,
    data: data,
    issuer: issuer,
    emissionDate: Date.now(),
    scheme: 1,
    topic: parseInt(topic),
    uri: "",
  });
  return claim;
}

function createSelfAttestedClaim(address, data, topic) {
  return createClaim(address, address, data, topic);
}

async function createCustomSigner(signer) {
  return new IdentitySDK.SignerModule({
    publicKey: await signer.getAddress(),
    signMessage: signer.signMessage.bind(signer),
  });
}

async function getClaim(identity, topic) {
  const address_identity = identity.address;
  const claimId = ethers.utils.keccak256(
    ethers.utils.defaultAbiCoder.encode(
      ["address", "uint256"],
      [address_identity, parseInt(topic)]
    )
  );

  if (claimId) {
    //The claims are auto-issued, it can only be one claim with this topic
    let claim = await identity.getClaim(claimId);
    return claim;
  } else {
    return;
  }
}

async function signClaim(claim, signer) {
  const claimSigned = await claim.sign(signer);
  return claimSigned;
}

async function prepareClaim(address, data, topic, signer) {
  let claim = createSelfAttestedClaim(address, data, topic);
  const customSigner = await createCustomSigner(signer);
  await signClaim(claim, customSigner);
  return claim;
}

async function sendClaimToBlockchain(identity, claim, signer) {
  return await identity.addClaim(
    claim.topic,
    claim.scheme,
    claim.issuer,
    claim.signature,
    claim.data,
    claim.uri,
    { signer: signer }
  );
}

export { getClaim, sendClaimToBlockchain, prepareClaim };
